<template>
  <div>
    <TitleWrapper :filter-option="false" title="Salary Template" :display-breadcrumb="true">
      <template v-slot:filterItems>
        <h2 v-i18n="result" class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
      </template>
    </TitleWrapper>
    <div class="minHieght100 main-content bg-primary-white rounded-t-lg mt-5 p-5">
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else-if="!isLoading && noRecord">
        <NoRecordFound />
      </div>
      <div v-else>
        <div class="m-1.5 font-medium">
          <div class="py-3 mt-2">
            Generator Title:
            <span class="text-menu pl-4">Generator Title</span>
          </div>
          <div class="py-3">
            Generate on day of month:
            <span class="text-menu pl-4">4</span>
          </div>
          <div class="py-3">
            Date:
            <span class="text-menu pl-4">
              {{ $filters.dateFormatInUTC('2022-12-2', TIME_CONSTANTS.DATE) }}
            </span>
          </div>
          <div class="py-3 mb-3 relative">
            Salary title pattern:
            <span class="text-menu pl-4">{YYYY}{MM}{AI:299}-Salary</span>
            <span
              class="float-right absolute right-0 z-50 pr-0 cursor-pointer"
              @click="toggleSalaryTitleModal"
            >
              <icon
                class="icon opacity-50 lg:opacity-100 cursor-pointer"
                color="white"
                icon="edit"
                height="15"
                width="15"
              />
            </span>
          </div>

          <hr class="border-primary-grey pb-6" />
        </div>
        <TableWrapper>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                <span v-i18n="dashboard">
                  {{ head }}
                </span>
              </TH>
              <TH>Actions</TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(salary, index) in salaryTemplateList" :key="index">
              <TD>
                <UiBadgeDisplay :options="[salary.staff_user]" :image="true" label="full_name" />
              </TD>
              <TD>
                {{ salary.base_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <span v-if="salary.salaries_allowances && salary.salaries_allowances.length">
                  <UiBadgeDisplay
                    :options="salary.salaries_allowances"
                    :image="true"
                    label="amount"
                    :nested-detail="['allowance_type', 'title']"
                  />
                </span>
                <span v-else>
                  {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </TD>
              <TD>
                <span v-if="salary.salaries_deductions && salary.salaries_deductions.length">
                  <UiBadgeDisplay
                    :options="salary.salaries_deductions"
                    :image="true"
                    label="amount"
                    :nested-detail="['deduction_type', 'title']"
                  />
                </span>
                <span v-else>
                  {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </TD>
              <TD>
                <span v-if="salary.extra_working && salary.extra_working.length">
                  <UiBadgeDisplay
                    :options="salary.extra_working"
                    :image="true"
                    label="amount"
                    :nested-detail="['unit_type', 'unit']"
                  />
                </span>
                <span v-else>
                  {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </TD>
              <TD>
                <TableAction
                  :idx="index"
                  :current-user="salary"
                  :action-list="actionList"
                  @action="typeAction"
                />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>

      <Pagination
        v-if="totalSalaryTemplatesCount > 10"
        v-show="!isLoading"
        :key="`ForceRender${forceRender}`"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(totalSalaryTemplatesCount, filteredRecordLimit)"
        @filterRecord="_getTemplateTemplate"
      />

      <SalaryTemplateModal v-if="showModal" :edit="editSalaryObject" @update="getNewData" />

      <UiConfirmationComponent
        heading="Delete Salary Template"
        message="Are you sure you want to delete template"
        button-text="Delete"
        :modal="deleteModelShow"
        :is-loading="isModalLoading"
        @confirm="deleteSalaryTemplateById"
        @cancel="toogleDeleteModel"
      />

      <UiConfirmationComponent
        heading="Generate Salaries"
        message="Are you sure you want to generate salaries"
        button-text="Generate"
        :modal="confirmModalShow"
        :is-loading="isModalLoading"
        @confirm="_generateSalariesFromTemplates"
        @cancel="toogleConfirmModel"
      />
      <SalaryTitle
        v-if="salaryTitleModal"
        v-model="salaryTitle"
        :modal="salaryTitleModal"
        title="Salary Title"
        example-pattern="{YY}{MMMM}{AI:299}-Salary"
        @toggle="toggleSalaryTitleModal"
      />
    </div>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import SalaryTemplateModal from '@components/salary-manager/SalaryTemplateModal.vue'
import TableAction from '@components/TableAction.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import SalaryTitle from '@src/router/views/settings/system-settings/registration-pattern/RegistrationPatternModal.vue'
import icon from '@components/icons/icon.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    Loader,
    TitleWrapper,
    Pagination,
    SalaryTemplateModal,
    TableAction,
    UiBadgeDisplay,
    SalaryTitle,
    icon,
    UiConfirmationComponent: () => import('@src/components/UiElements/UIConfirmationModal.vue'),
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin],
  data() {
    return {
      forceRender: 0,
      salaryTitle: null,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      deleteModelShow: false,
      totalSalaryTemplatesCount: 0,
      tableHeaders: ['Staff', 'Base Salary', 'Allowance', 'Deduction', 'Extra Working Hour'],
      salaryTemplateList: [],
      noRecord: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      filteredRecordLimit: 10,
      editSalaryObject: null,
      currentSalaryObject: {},
      salaryTitleModal: false,
      salaryGeneratorId: this.$route.params.id,
      GENERAL_CONSTANTS,
      confirmModalShow: false,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
      showModal: (state) => state.salary.showModal,
      isModalLoading: (state) => state.salary.isModalLoading,
    }),
  },
  watch: {
    showModal: {
      handler(value) {
        if (!value) this.editSalaryObject = null
      },
    },
  },
  created() {
    this.setRightbar()
    this._getTemplateTemplate()
  },
  methods: {
    getNewData() {
      this._getTemplateTemplate()
      this.editSalaryObject = null
      this.forceRender++
    },

    toggleSalaryTitleModal() {
      this.salaryTitleModal = !this.salaryTitleModal
    },

    toogleConfirmModel() {
      this.confirmModalShow = !this.confirmModalShow
    },

    deleteSalaryTemplateById() {
      this.delSalaryTemplate(this.currentSalaryObject.id)
    },

    async delSalaryTemplate(id) {
      const [res, err] = await this.deleteSalaryTemplate(id)
      this.toogleDeleteModel()
      this.getNewData()
    },

    async _getTemplateTemplate(range) {
      const payload = {
        pagination: {
          skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
          limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
        },
        id: this.salaryGeneratorId,
      }
      const [res, err] = await this.getSalaryTemplates(payload)
      this.salaryTemplateList = res.data.records.map((rec) => {
        rec.staff_user.full_name = `${rec.staff_user.first_name} ${rec.staff_user.last_name}`
        if (rec.extra_working_unit && rec.extra_working_unit_rate)
          rec.extra_working = [
            { amount: rec.extra_working_unit_rate, unit_type: rec.extra_working_unit },
          ]
        return rec
      })
      this.totalSalaryTemplatesCount = res.data.meta.total_records
    },

    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
    },

    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          this.editSalaryObject = currentType
          this.setModal(true)
          break
        case 'Delete':
          this.currentSalaryObject = currentType
          this.toogleDeleteModel()
          break
      }
    },

    async _generateSalariesFromTemplates() {
      const [res, err] = await this.generateSalariesFromTemplates(this.salaryGeneratorId)
      if (res) {
        this.toogleConfirmModel()
      }
    },

    setRightbar() {
      this.setRightbarContent({
        header: {
          title: 'Salary',
          buttons: [
            {
              title: 'Add Salary Template',
              classes: ['primary-button-right'],
              action: { name: 'salary/showModal' },
            },
            {
              title: 'Generate Instant Salary',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toogleConfirmModel()
                },
              },
            },
          ],
        },
      })
    },

    ...mapActions('salary', [
      'getSalaryTemplates',
      'setModal',
      'deleteSalaryTemplate',
      'generateSalariesFromTemplates',
    ]),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
